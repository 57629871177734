<template>
  <div>
    <topBarY />
    <div class="bg">
      <img src="../../assets/common/pic/bgs.jpg" alt="">
    </div>
    <div class="switchBar">
      <div class="tab contact">青海油田无线传输监控项目</div>
    </div>
    <div class="line"></div>
    <div class="switchContiner">
      <div class="continer contactContiner">
        <div class="txt">
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;青海油田在方圆150 平方公里的油田开采区城内，在各单井、计转站和联合站等生产现场，
          设置无线监控点;在油田总控案，建立系统中心基站。选用新诺同络的电信级无线网桥产品，
          配合高增盐天线，采用点对点及点对多点的无线连接方式，建立油田总控室与各监控点间的无线传输主链路，
          实现生产现场图像情息、生产数据、设备运转数据、原油储备和运输信息等的实时动态传输，
          为采油生产的指挥调度提供准确及时的第一手资料。同时，在很多无人职守的油田生产区域，
          原油及其生产设施的安全保卫工作，也是相关部门工作的重中之重。生产现场的无线远程视频监控网络，
          为生产现场的安全保卫工作提供了强有力的支持，保障了油田保卫部门对不法犯罪活动的快速打击。<br>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;各监控点由视频采集处理系统和信号传输系统两大部分组成，其中视频采集处理系统包括了摄像机、云台及其控制器、网络视频服务器;
          信号传输系统由无线网桥、天情系统等构成。系统采用先进的数字处理技术，
          将监控摄像机拍摄的阳像信息处理为基于TCPAP 的数据包，通过无线网塔传输到远端监控中心。<br>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;监控中心基站由电信级无线网桥与天馈系统组成。监控阳像的实时显示、存档、童询等功能，
          全部由计算机系统或数字硬盘录像机完成。通过授权可以达成系统资源的共享，
          实现通过LAN 或INTERNET 的远程监控，远端险控中心也可拉制前端监控摄像机，
          根据操作人员的需要，拍摄不同方位、不同尺度的生产现场视频图像
        </div>
        <div>

        </div>
      </div>
    </div>
    <baseBar />
  </div>
</template>

<script>
import topBarY from '@/components/topBarY.vue'
import baseBar from '@/components/baseBar.vue'

export default {
  name: 'caseTwo',
  components: {
    topBarY,
    baseBar
  },
  created() {
    this.$nextTick(() => {
      // 1.禁用右键菜单
      document.oncontextmenu = new Function("event.returnValue=false");
      // 2.禁用鼠标选中
      document.onselectstart = new Function("event.returnValue=false");
      // 3.禁止键盘F12键
      document.addEventListener("keydown", function (e) {
        if (e.key == "F12") {
          e.preventDefault(); // 如果按下键F12,阻止事件
        }
      });
    });
  }
}
</script>

<style scoped>
.bg {
  position: relative;
  width: 1920px;
  height: 480px;
  overflow: hidden;
}

.bg img {
  position: absolute;
  top: -100px;
  width: 1920px;
}

.switchBar {
  width: 1920px;
  height: 70px;
  background-color: #fff;
  text-align: center;
}

.switchBar .tab {
  display: inline-block;
  margin-right: 30px;
  margin-top: 15px;
  border-radius: 5px;
  width: 230px;
  height: 40px;
  background-color: #c3a35d;
  color: #f5f5f5;
  font-family: 'Microsoft Yahei';
  font-size: 16px;
  line-height: 40px;
}

.line {
  margin: 0 auto;
  width: 1400px;
  height: 1px;
  background-color: #c3a35d;
}

.switchContiner {
  width: 1920px;
  padding: 20px;
  background-color: #f7f7f7;
}

/* 关于宁讯 */
.switchContiner .contactContiner {
  margin: 0 auto;
  padding: 40px;
  padding-left: 300px;
  padding-right: 300px;
  width: 1400px;
  height: 600px;
  border-radius: 5px;
  background-color: #fff;
}

.switchContiner .contactContiner .txt {
  font-size: 18px;
  line-height: 34px;
}
</style>
